var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('base-material-card',{attrs:{"icon":"mdi-alarm-multiple","title":"Aktuelle Ligen","color":"primary"}},[_c('v-data-table',{attrs:{"headers":[
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Von', value: 'startDate', sortable: true },
        { text: 'Bis', value: 'endDate', sortable: true }
      ],"items":_vm.events,"sort-by":"startDate","sort-desc":"","mobile-breakpoint":"0"},on:{"click:row":_vm.open},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.name))]),(item.untertitel)?_c('span',{staticStyle:{"font-style":"italic"}},[_vm._v(" ("+_vm._s(item.untertitel)+") ")]):_vm._e()]}},{key:"item.startDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.endDate))+" ")]}}])}),_c('v-switch',{attrs:{"label":"alte Events anzeigen"},model:{value:(_vm.events_all),callback:function ($$v) {_vm.events_all=$$v},expression:"events_all"}})],1),_c('v-row',[_vm._l((_vm.events),function(e){return [(e.type === 'stb_m_2021')?[_c('v-col',{key:("start-" + (e._id)),attrs:{"md":"6","cols":"12"}},[_c('stb-m2021-start',{attrs:{"id":e._id}})],1),_c('v-col',{key:("news-" + (e._id)),attrs:{"md":"6","cols":"12"}},[_c('stb-m2021-news',{attrs:{"id":e._id}})],1)]:_vm._e(),(e.type === 'stb_w_2023')?[_c('v-col',{key:("start-" + (e._id)),attrs:{"md":"6","cols":"12"}},[_c('stb-w2023-start',{attrs:{"id":e._id}})],1),_c('v-col',{key:("news-" + (e._id)),attrs:{"md":"6","cols":"12"}},[_c('stb-w2023-news',{attrs:{"id":e._id}})],1)]:_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }